import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../App";
import "./Domainpage.css"; // Make sure the path is correct
import axios from "axios";

const Domainpage = () => {
  const navigate = useNavigate();
  const {
    setSelectedOption,
    setSelectedLevel,
    setSelectedLanguages,
    setUserData,
    setSessionData,
  } = useContext(MyContext);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  // const handleNextPage = () => {
  //   if (selectedOption) {
  //     if (selectedOption === "IT") {
  //       navigate("/levels");
  //     } else if (selectedOption === "Non-It") {
  //       navigate("/nonitpages");
  //     }
  //   } else {
  //     alert("Please select an option before proceeding.");
  //   }
  // };

  const fetchAllUsersData = async () => {
    try {
      const response = await axios.get(
        "https://www.interviewaxis.com/api/v1/user-data",
        { withCredentials: true }
      );

      if (response.status >= 200 && response.status < 300) {
        return Array.isArray(response.data.data) ? response.data.data : [];
      } else {
        throw new Error("Failed to fetch users data");
      }
    } catch (error) {
      console.error("Error fetching users data:", error);
      return [];
    }
  };
  const checkSessionStatus = async () => {
    try {
      const response = await fetch('https://www.interviewaxis.com/api/v1/check-session', {
        method: 'GET', 
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json(); 
  
      const interviewData = data.interviewData;
      
 
      //console.log(interviewData);
  
      if (interviewData) {
        setSessionData(interviewData); 
  
      
        setSelectedOption(interviewData.industry === "1" ? "IT" : "NON IT");
        setSelectedLanguages(interviewData.technologies_selected);
  
        let level;
        if (interviewData.level_selected === "1") {
          level = "Basic";
        } else if (interviewData.level_selected === "2") {
          level = "Intermediate";
        } else {
          level = "Expert";
        }
  
        setSelectedLevel(level);
      }
  
      return data; 
    } catch (error) {
      console.error("Error checking session status:", error);
      return null;
    }
  };
  

  const processSessionData = async () => {
    const usersData = await fetchAllUsersData();
    const sessionData = await checkSessionStatus();

   



    if (sessionData && sessionData.logged_in===true && sessionData.interviewData.technologies_selected) {
      const loggedInUser = sessionData.user;
      const matchedUser = usersData.find((user) => user.id === loggedInUser.id);

     

      if (matchedUser) {
        setUserData({
          user_id: matchedUser.id,
          user_name: matchedUser.name,
          user_role: loggedInUser.role,
        });
        if (sessionData.interviewData.interview_type === "2") {
          navigate("/custominterview");
        }else{
          navigate("/InterviewSereen");
        }
        
      } else {
        console.error("User data not found in users data");
      }
    } else {
       window.location.href = "https://www.interviewaxis.com/login";
    }
  };

  useEffect(() => {
    processSessionData();
  }, []);

  return (
    <div className="it-or-non-it-page">
      {/* Content or loading spinner can be added here */}
    </div>
  );
};

export default Domainpage;
